import { Link, graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'

const IndexPage = ({ data }) => {
  return (
      <Layout>
        <div className="flex justify-center">
          <div className="bg-white max-w-7xl flex items-center flex-col pb-14">


            <div className="text-2xl m-5">Search by Category</div>
            <div  className="flex flex-wrap  justify-center text-white">
              
              
              <div className="border-red border    relative text-center ">
                <p className="border-white border  p-3 text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Education & Teaching</p>
                <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/education.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border  p-3 text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Sales</p>
                
              <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/technology.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border p-3  text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Software Engineering</p>
                <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/software.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border p-3  text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Customer Service</p>
                
              <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/graduate.jpg" alt="" />
              </div>


            </div>
         





              <div className="flex flex-wrap justify-center  md:justify-between  text-center m-6  text-blue-600  ">
                {data.category.edges.map(edge => 
                  <Link to="/" className="m-1 w-60" key={edge.node.id}>{edge.node.categoryName}</Link>

                )}
              </div>




              <div className="text-2xl m-5">Search by Testing Services</div>

                <div  className="flex flex-wrap  justify-center text-white">

                  {data.testing.edges.map(edge => 
                    <Link to="/" key={edge.node.id} >
                    
                    <div className="border border-orange relative text-center h-48 w-60 m-6" style={{backgroundImage : `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(https://pisum.photos/200)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}  >
                      <p className="border-white border p-3  text-3xl  absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">{edge.node.serviceName}</p>
                    </div>


                    </Link>

                  )}

              </div>











            <div className="text-2xl m-5">Search by State</div>

            <div  className="flex flex-wrap  justify-center text-white">

                {data.state.edges.map(edge => 
                  <Link to="/" key={edge.node.id} >
                  
                  <div className="border border-orange relative text-center h-48 w-60 m-6" style={{backgroundImage : `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${edge.node.img.fluid.srcWebp})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}  >
                    <p className="border-white border p-3  text-3xl  absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">{edge.node.stateName}</p>
                  </div>


                  </Link>

                )}

            </div>





            <div className="text-2xl m-5">Search by City</div>

            <div  className="flex flex-wrap  justify-center text-white">
              <div className="border-red border    relative text-center ">
                <p className="border-white border  p-3 text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Lahore</p>
                <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/de/berlin.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border  p-3 text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Karachi</p>
                
              <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/de/cologne.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border p-3  text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Rawalindi</p>
                <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/de/hamburg.jpg" alt="" />
              </div>

              <div className="border-red border    relative text-center ">
                <p className="border-white border p-3  text-3xl absolute top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2">Peshawar</p>
                
              <img className="w-60 m-6" src="https://www.thelocal.de/wp-content/themes/thelocal/assets/images/jobs/de/munich.jpg" alt="" />
              </div>

            </div>

            <div className="flex flex-wrap justify-center  md:justify-between  text-center m-6  text-blue-600  ">
                {data.city.edges.map(edge => 
                  <Link to="/" className="m-1 w-60" key={edge.node.id}>{edge.node.cityName}</Link>

                )}
            </div>




          </div>
        </div>
      </Layout>
  )
}

export default IndexPage




export const query = graphql`
  {
    city : allContentfulCity {
      edges {
        node {
          id
          cityName
        }
      }
    }
    category : allContentfulCategory {
      edges {
        node {
          id
          categoryName
        }
      }
    }
    testing :  allContentfulTestingService {
      edges {
        node {
          serviceName
          id
        }
      }
    }
    state : allContentfulState {
      edges {
        node {
          id
          stateName
          img {
            fluid(maxWidth: 192, maxHeight: 240) {
              srcWebp
            }
          }
        }
      }
    }
  }
`